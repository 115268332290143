import { Component, OnInit, ElementRef } from '@angular/core';
import { ZonasService } from '../zonas.service';
import { AppPlanes } from './app.planes';
import { FilterPrestador } from './filter.prestador';


declare var $: any;

@Component({
  selector: 'filter-zona',
  templateUrl: './filter.zona.html'  
})

export class FilterZona {

  callZonaSolo() {
    //alert('Call callZonaSolo!');
  }

  Lzonas = null;
  
  Lzona = [{ id: null, nombre: null, planes: null }]

    constructor(private zonasServicio: ZonasService, private el: ElementRef, public planesApp: AppPlanes, public filterPrestador: FilterPrestador) {}
  
    ngOnInit() {
      this.recuperarTodos();
    }

    recuperarTodos() {
      this.zonasServicio.recuperarTodos().subscribe(result => { 
        this.Lzonas = result;
        this.Lzonas.splice(5, 1);
        //console.log(this.Lzonas);        
        
        
      });
    }
   
    hayRegistros() {
      return true;
    } 

    showsolozona(el) {
      let Tagshow = document.getElementById('showSolozona-'+el);
      let Taghide = document.getElementById('showplaneszonasolo-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showtodozona(el) {
      let Tagshow = document.getElementById('showTodozona-'+el);
      let Taghide = document.getElementById('showplaneszonatodo-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showplanessolozona(el) {
      let Tagshow = document.getElementById('showplaneszonasolo-'+el);
      let Taghide = document.getElementById('showSolozona-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showplanestodozona(el) {
      let Tagshow = document.getElementById('showplaneszonatodo-'+el);
      let Taghide = document.getElementById('showTodozona-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    filtroZona(event) { 
      //console.log(event.id);
      let zona = event.id.split('-');
      let checkZonas = '';
      let paramcheckZonas = '';

      $('#ZonasList input[type=checkbox]').each(function(a: any, b: any){
        let idcheck = '#'+b.id;
        if ($(idcheck).prop('checked') == true) { checkZonas += idcheck.replace('#zona-','') + ',' }
      });
      paramcheckZonas = checkZonas.slice(0, -1);
      //console.log(paramcheckZonas);

      this.filterPrestador.listaPrestadores(paramcheckZonas);
      //this.filterPrestador.filtroPrestador(zona);
      
      this.planesApp.SendFilterPlanes();
    }

}
