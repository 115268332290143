import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild, Input, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { of, pipe } from 'rxjs';
import { Observable } from 'rxjs';
import { PlanesService } from '../planes.service';
//import { AppPlanesSearch } from './app.planes.search';
import { Plan } from './planes.model';
import { Subject } from 'rxjs';
import { Routes, RouterModule } from '@angular/router';
//import { FilterPrestador } from './filter.prestador'; 
import swal from 'sweetalert2'; 
import { UfService } from '../uf.service';
import {NgbPopoverConfig} from '@ng-bootstrap/ng-bootstrap';

//declare function downloadFile(Url); 

declare var $: any;

@Component({
	selector: 'app-planes',  
	templateUrl: './app.planes.html',  
	template: `<h2>{{count}}</h2>`,
	providers: [NgbPopoverConfig]   
})

export class AppPlanes implements OnInit, AfterViewInit {

	planes = null;
	plan = [{ id: null, 
			  codigo: null, 
			  nombre: null, 
			  base: null, 
			  isapre: null, 
			  hospitalaria: null, 
			  ambulatoria: null, 
			  planUF: null, 
			  planPesos: null,
			  planPdf: null,
			  tipo: null,
			  zona: null,
			  logo: null,
			  compara: null,
			  ischecked: null,
			  benefia: null
			 }]

	params = 'A';
	parametros = '';
	factores = '';
	marcaZona = 0;
	public checkedPlanes = []; 
	public valorUF = null;
	
	url = 'http://localhost/';
	pageOfItems: Array<any>;
	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(config: NgbPopoverConfig, private planesService: PlanesService, 
						private ngZone: NgZone, 
						private changeDetection: ChangeDetectorRef,
						//private numPlan: AppPlanesSearch,
						private routes: RouterModule,
						private renderer: Renderer2,
                  		private el: ElementRef,
						private ufServicio: UfService

	) {
		config.placement = 'right';
		config.triggers = 'hover';		

	}

	POSTS: any;
	page = 0;
	count = 0;
	tableSize = 12;
	tableSizes = [5, 10, 20, 50, 100];
	namePlanes = 'planes';
	funcParan  = '';
	parameter  = '';
	UFvalor    = '';
	beneficiarios = '';
	public order = 0;
	ordena = 2;
	pagina = false;
	SearchText = '';
	public prestadorZonas = '';
	isValid = true;
	
	beginer() {
		$("#amountClick").trigger("click");
	}

    isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
       } else {
            return false 
       }
    } 

	closeToggler() {
		let Tagview = document.getElementById('toggle-view');
		let Tagclose = document.getElementById('toggle-close');
		Tagview.classList.remove('hidden');
		Tagclose.classList.add('hidden');
		$('.sidebar-shop').removeClass('show');
		$('.body-content-overlay').removeClass('show');
		$('.body').removeClass('modal-open');
	}

	viewToggler() {
		let Tagview = document.getElementById('toggle-view');
		let Tagclose = document.getElementById('toggle-close');
		Tagview.classList.add('hidden');
		Tagclose.classList.remove('hidden');
		$('.sidebar-shop').addClass('show'); 
		//$('.body-content-overlay').addClass('show'); 
  		//$('.shop-sidebar-toggler')[0].click();
	}

	viewPlan(pdf, label, isapre, id) {  
		//console.log(pdf);
		if (this.isMobile() == false) {
		var content = "<object id='pdfObject' type='application/pdf' data='https://www.pdfplan.cl/viewplan/" + pdf + "'?#zoom=150 width='100%' height='600px' />";
		$("#pdfData").empty();
		$('#pdfData').html(content);
		$('#pdfLabel').html(isapre + ' : ' + label);
		$('#planViewer').modal('show');
		} else {
			var pdfPlanID = 'pdf-'+id;
			//$('#'+pdfPlanID).trigger("click");
			$('#'+pdfPlanID)[0].click();
			//console.log($("#"+pdfPlanID).attr("href"));
		}
		return false; 
	}

	ngOnInit() {  
		//this.getAllPlanes(this.params);
		this.ufServicio.recuperarUF().subscribe(result => { this.valorUF = result; $("#UFvalor").val(result); this.SendFilterPlanes(); } );
		setTimeout(this.beginer,1000);
		$('#products').addClass('hidden');
		$('#loaderThis').removeClass('hidediv');
		$('.item-description').popover();
		/* 
		if (this.isMobile() == false) { 
			var btnContent = '<button type="button" (click)="onClick(msgClose)" class="btn btn-info btn-sm"><i class="far fa-eye-slash"></i> Ocultar</button>';
			$('#buttonshowmsg').html(btnContent);
		} else { 
			//var btnContent = '<div class="buy-now d-block d-sm-none"><a href="javascript:void(0)" id="toggle-view" (click)="viewToggler()" class="btn btn-danger btn-block"><span></span><i data-feather="filter"></i> Ingresar Perfil</a><a href="javascript:void(0)" id="toggle-close" (click)="closeToggler()" class="hidden btn btn-danger btn-block"><span></span><i data-feather="filter"></i> Ocultar Filtros</a></div>';
			var btnContent = '<button type="button" class="btn btn-danger btn-sm" (click)="viewToggler1()"><i class="far fa-filter"></i> Ingresar Perfil</button>';
			$('#buttonshowmsg').html(btnContent);
		 }
		 */
		//this.recuperarUF();
	}

	viewToggler1() {
		let Tagview = document.getElementById('toggle-view');
		let Tagclose = document.getElementById('toggle-close');
		Tagview.classList.add('hidden');
		Tagclose.classList.remove('hidden');
		$('.sidebar-shop').addClass('show'); 
		$('#messageCard1').addClass('hidden');


		//$('.body-content-overlay').addClass('show');
  		//$('.shop-sidebar-toggler')[0].click();
	}

	recuperarUF() {
		this.ufServicio.recuperarUF().subscribe(result => { this.valorUF = result; }, error => console.log('error!!! : '+error) );
	}

    soloIsapreClick(valor) {
        $('#IsapresList input[type=checkbox]').each(function(a,b){
			var idcheck = '#'+b.id;
			var n = b.id.split("-");
            //console.log('idcheck : '+idcheck +' - valor : '+valor);
			if (n[1] != valor) {
				//console.log('false : '+' n[1] : '+n[1]+' - valor : '+valor+' - idcheck : '+idcheck);
				$(idcheck).prop('checked', false); 
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloIsapreID-'+theId[1]);
				let TagTodo = document.getElementById('todoIsapreID-'+theId[1]);
				TagSolo.classList.remove('hidden');
				TagTodo.classList.add('hidden');
			} else { 
				//console.log('true : '+' n[1] : '+n[1]+' - valor : '+valor+' - idcheck : '+idcheck);
				$(idcheck).prop('checked', true);
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloIsapreID-'+theId[1]);
				let TagTodo = document.getElementById('todoIsapreID-'+theId[1]);
				TagSolo.classList.add('hidden');
				TagTodo.classList.remove('hidden');
			}
		});
		//return false;
		this.SendFilterPlanes();
	}

    todoIsapreClick(valor) {  
        $('#IsapresList input[type=checkbox]').each(function(a,b){
			var idcheck = '#'+b.id;
				$(idcheck).prop('checked', true);
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloIsapreID-'+theId[1]);
				let TagTodo = document.getElementById('todoIsapreID-'+theId[1]);
				TagTodo.classList.add('hidden');
				TagSolo.classList.remove('hidden');
		});
		this.SendFilterPlanes();
	}

	setSearchText(tagSerach) {
		this.SearchText = tagSerach;
		//console.log('SearchText : '+this.SearchText);
		this.SendFilterPlanes();
	}

	setSearchTextArray(tagSerach) {
		this.planes.filter((hospitalaria: Plan) => hospitalaria.hospitalaria === tagSerach);
	}
 
    soloZonaClick(valor) {
        $('#ZonasList input[type=checkbox]').each(function(a,b){
			var idcheck = '#'+b.id;
			var n = b.id.split("-");
			if (n[1] != valor) { 
				$(idcheck).prop('checked', false); 
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloZonaID-'+theId[1]);
				let TagTodo = document.getElementById('todoZonaID-'+theId[1]);
				TagSolo.classList.remove('hidden');
				TagTodo.classList.add('hidden');
			} else { 
				$(idcheck).prop('checked', true); 
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloZonaID-'+theId[1]);
				let TagTodo = document.getElementById('todoZonaID-'+theId[1]);
				TagSolo.classList.add('hidden');
				TagTodo.classList.remove('hidden');
			}
		});
		this.SendFilterPlanes();
	}

    todoZonaClick(valor) {
        $('#ZonasList input[type=checkbox]').each(function(a,b){
			var idcheck = '#'+b.id;
				$(idcheck).prop('checked', true);
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloZonaID-'+theId[1]);
				let TagTodo = document.getElementById('todoZonaID-'+theId[1]);
				TagTodo.classList.add('hidden');
				TagSolo.classList.remove('hidden');
		});
		this.SendFilterPlanes();
	}

    todoTipoClick(valor) {
        $('#TiposList input[type=checkbox]').each(function(a,b){
			var idcheck = '#'+b.id;
				$(idcheck).prop('checked', true);
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloTipoID-'+theId[1]);
				let TagTodo = document.getElementById('todoTipoID-'+theId[1]);
				TagTodo.classList.add('hidden');
				TagSolo.classList.remove('hidden');
		});
		this.SendFilterPlanes();
	}

    soloTipoClick(valor) {
        $('#TiposList input[type=checkbox]').each(function(a,b){
			var idcheck = '#'+b.id;
			var n = b.id.split("-");
			if (n[1] != valor) { 
				$(idcheck).prop('checked', false); 
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloTipoID-'+theId[1]);
				let TagTodo = document.getElementById('todoTipoID-'+theId[1]);
				TagSolo.classList.remove('hidden');
				TagTodo.classList.add('hidden');
		    } else { 
				$(idcheck).prop('checked', true);
				var theId = idcheck.split('-');
				let TagSolo = document.getElementById('soloTipoID-'+theId[1]);
				let TagTodo = document.getElementById('todoTipoID-'+theId[1]);
				TagSolo.classList.add('hidden');
				TagTodo.classList.remove('hidden');
			}
		});
		this.SendFilterPlanes();
	}

	ngAfterViewInit() {
		//setTimeout(this.beginer,3000);
		//$('#zona-5').attr('checked', true);
		//$("#zona-5").trigger("click");
		//console.log($('#zona-5').attr());
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	onTableDataChange(event) {
		this.page = event;
	}

	getAllPlanes(params) {



		this.planesService.getData(params).subscribe( 
			(data: any[]) => {
				this.planes = data;
				let numero = this.planes.length;
				$("#numPlanes").html(numero.toLocaleString('de-DE') );
			 }, 
				error => console.log('error : '+error) 
			);
	}

	itemsTrackedBy(index, item) {
		return item.text;
	}

	SendFilterFactores(factor,numero) {
		$('#products').addClass('hidden');
		$('#loaderThis').removeClass('hidediv');
		$('#loaderThis').addClass('showdiv');

		this.factores = factor;
		this.beneficiarios = numero;
		//console.log('numero : '+numero);
		this.ordena = 0;
		//$('#ordena').val('1');
		this.SendFilterPlanes();
	}

	msgClose() {
		$('#messageCard1').addClass('hidden');
		$('#messageCard').addClass('hidden');
	}


	SendFilterPlanes() {   
		//console.log('SendFilterPlanes order : '+this.order);
		//console.log('ordena : '+$('#ordena').val());
		this.order = $('#ordena').val();
		var params = '';
		var filtroIsapres = '';
		var filtroZonas = '';
		var filtroTipos = '';
		var numeralfrom = '';
		var numeralto = '';
		var filtroMontos = '';
		this.UFvalor = 'U--'+$("#UFvalor").val();

		// PARAMETROS ISAPRES //

		//let paramIsapres = $("#IsapresList input:checkbox:checked");
		//for (let i = 0; i < paramIsapres.length; i++) {
		//   var n = paramIsapres[i].id.split("-");
		//   filtroIsapres = filtroIsapres + n[1] + ',';
		   //console.log (n[1]);
	  	//}
		//if (filtroIsapres !== '') {
		//	filtroIsapres = filtroIsapres.slice(0, -1)
			filtroIsapres = 'I--6' + filtroIsapres;
		//}
		//console.log (filtroIsapres);

		// PARAMETROS ZONAS //
		if (this.marcaZona == 0) {
 		    $('#zona-5').prop('checked', true);
			 this.marcaZona = 1;
	    }

		let paramZonas = $("#ZonasList input:checkbox:checked");
		for (let i = 0; i < paramZonas.length; i++) {
		   var n = paramZonas[i].id.split("-");
		   filtroZonas = filtroZonas + n[1] + ',';

		   let paramPrestadores = $("#PrestadoresList li");
		   for (let j = 0; j < paramPrestadores.length; j++) {
			   //console.log(paramPrestadores[j].id);
			   var k = paramPrestadores[j].id.split("-");
			   let Tagshow = document.getElementById(k[0]+'-'+k[1]+'-'+k[2]);
			   //console.log(n[1]+' : '+Tagshow);
			   if ( k[2].indexOf(n[1]) !== -1 ) {
				  Tagshow.classList.remove('hidden');	
				  Tagshow.classList.remove('hide');	
				  //console.log(Tagshow);
				  //console.log(k);
			   }
			   //var p = k[2].split(",");
			   //for (let x = 0; x < p.length; x++) {
			      //let Tagshow = document.getElementById('fila-'+k[1]+'-'+p[x]);
			   	  //console.log('fila-'+j[1]+'-'+p[x]);
			   	  //console.log(Tagshow);
			   	  //Tagshow.classList.remove('hidden');					   
			   //}
		   }
		   //console.log (n[1]);
		}
		if (filtroZonas !== '') {
			filtroZonas = filtroZonas.slice(0, -1);
			this.prestadorZonas = filtroZonas;
			filtroZonas = 'Z--' + filtroZonas;
		}
		if (filtroZonas == '') { 
			$('#prestadores').val('');
			$('#PrestadoresList input[type=checkbox]').each(function(a,b){
				var idcheck = '#'+b.id;
				$(idcheck).prop('checked', false); 
			});
		}

		//console.log ('filtroZonas : '+filtroZonas);
		//console.log ('prestadores : '+$('#prestadores').val());
		
		//return false;
		//console.log('prestadorZonas : '+this.prestadorZonas);
		//this.filterPrestador.getPrestadores(this.prestadorZonas);


		// PARAMETROS TIPOS //

		let paramTipos = $("#TiposList input:checkbox:checked");
		for (let i = 0; i < paramTipos.length; i++) {
		   var n = paramTipos[i].id.split("-");
		   filtroTipos = filtroTipos + n[1] + ',';
		   //console.log (n[1]);
		}
		if (filtroTipos !== '') {
			filtroTipos = filtroTipos.slice(0, -1)
			filtroTipos = 'T--' + filtroTipos; 
		}
		//console.log (filtroZonas);

		// PARAMETROS MONTOS //

		//numeralfrom = (<HTMLInputElement>document.getElementById("numeralfrom")).value;
		//numeralto = (<HTMLInputElement>document.getElementById("numeralto")).value;

		numeralfrom = $('#numeralfrom').val();
		numeralto = $('#numeralto').val();

		//console.log('numeralfrom : '+numeralfrom);
		//console.log('numeralto : '+numeralto);

		numeralfrom = numeralfrom.replace("$", "");
		numeralfrom = numeralfrom.replace(".", "");
		numeralfrom = numeralfrom.replace(".", "");
		numeralto = numeralto.replace("$", "");
		numeralto = numeralto.replace(".", "");
		numeralto = numeralto.replace(".", "");

		if (numeralfrom == '' && numeralto == '') {
			filtroMontos = 'M--0,500000';
		} else {
			filtroMontos = 'M--' + numeralfrom + ',' + numeralto;
		}
  
		//console.log('factores : '+this.factores);
		//console.log('beneficiarios : '+this.beneficiarios);

		if (this.factores != '' && this.factores != '0.00') { params += 'F--' + this.factores + '-|'; } else { params += 'F--0.9--x' + '|'; }
		if (filtroIsapres != '') { params += filtroIsapres + '|'; }
		if (filtroZonas != '') { params += filtroZonas + '|'; }
		if (filtroTipos != '') { params += filtroTipos + '|'; }
		params += filtroMontos + '|';
		params += this.UFvalor + '|';
		if (this.beneficiarios != '' && this.beneficiarios != '0') { params += 'B--' + this.beneficiarios + '|'; } else { params += 'B--1' + '|'; }
		params += 'O--' + this.order + '|';
		params += 'S--' + this.SearchText + '|';

		if ($('#prestadores').val() != '') {
			params += $('#prestadores').val(); 
		}

		//console.log('beneficiarios : '+this.beneficiarios);
		//console.log('params : '+params);

		
		//console.log ('params : '+params); 
		
		//return false;

		//return false;
		document.getElementById("dataCall").setAttribute('role', params);
		//console.log('theButton : '+document.getElementById("dataCall").getAttribute('value'));
		//this.funcParan = "getFilterPlanes";
		//this.parameter = params;
		$("#dataCall").trigger("click"); 
		//console.log('trigger');
		//console.log($("#dataCall").click());
	}

	callFilter() {
		let parameter = document.getElementById("dataCall").getAttribute('role');
		//console.log('parameter : '+parameter);
		this.getFilterPlanes(parameter);
		//console.log(event);
	}

	loadPrestadores(zonas) {
	}

	estePlan(planID) {



		//console.log('aquí!');

		$('#compareParamsSet').val(planID+',');

		let compareParamsSet = $('#compareParamsSet').val();  
		//console.log('compareParamsSet : '+compareParamsSet);
	
		//let listaPlanes = $("#ecommerce-products input:checkbox:checked");
		/* 
		if (compareParamsSet == '') {
		  swal.fire({
			title: "tu7porciento",
			text: "Debe seleccionar planes para continuar!",
			icon: "error",
			confirmButtonText: "Aceptar",
			customClass: {
				confirmButton: "btn btn-primary"
			},
			buttonsStyling: !1
		  })
		  return false;
		}
		*/
	   
		  let url='https://apivendor.tu7.cl/';
		  let listaCompare = compareParamsSet;
		  let CountBeneficiarios = $('#personList tr').length - 1;
		  let sumafactores = $('#sumFactor').html();
		 //if (sumafactores == '0.00') { sumafactores = '0.9'; }
		  let valorUF = $('#UFvalor').val();

		  //console.log('sumafactores : '+sumafactores);

		  if (sumafactores == '0.00') { 
			$( "#toTop" ).trigger( "click" );
			//$("html, body").scrollTop(0);
			//window.scrollTo({ top: 100, left: 100, behavior: 'smooth' });
			swal.fire({
				title: "Atención!",
				html: 'Para calcular el valor final de tu plan, debes ingresar edad de cada beneficiario (titular y cargas).' +
				      '<br><br><img src="https://common.tu7.cl/beneficiarios.png" width="70%">',
				confirmButtonText: "Aceptar",
				customClass: {
					confirmButton: "btn btn-primary"
				},
				buttonsStyling: !1,
				onAfterClose: () => window.scrollTo(0,0)
			})			  
			  //$( "#personEdad" ).focus();
			  //$("html, body").scrollTop(0);
			  //$( "#personEdad" ).focus();
			  return false;
			}
		  
		  //for (let i = 0; i < this.planesApp.checkedPlanes.length; i++) {
		  //   listaCompare += this.planesApp.checkedPlanes[i] + ',';
		  //}
	
		  //for (let i = 0; i < listaPlanes.length; i++) {
		  //   let TagCheckes = document.getElementById(listaPlanes[i].id);
		  //   var n = TagCheckes.id.split("-");
		  //   let idcheck = n[1];
		  //   listaCompare += idcheck + ',';
		  //}
			listaCompare = listaCompare.slice(0, -1);
			//console.log('listaCompare : '+listaCompare);
			//this.planesService.getPlanCompare(listaCompare,CountBeneficiarios,sumafactores,valorUF).subscribe(result => this.Cplanes = result);
	  
		  //return false;
	
		  //$("#CompareData").empty();
		  //$('#CompareData').html('comparador');
		  $('#selectedMail').modal('show');
		  return false;
	  
		}  
	

	clearSelected() {
		let listaPlanes = $("#ecommerce-products input:checkbox:checked");
		for (let i = 0; i < listaPlanes.length; i++) {
			 let TagCheckes = document.getElementById(listaPlanes[i].id);
             let idcheck = TagCheckes.id;
			 $('#'+idcheck).attr('checked',false);
			 $('#'+idcheck).prop('checked',false);
			 //console.log(idcheck);
             //document.getElementById(idcheck).click();
		}
	}

	checkValue(event) {
		//console.log(event.id);
		if (event.checked == false) {
			var thexID = event.id.split("-");
			const index = this.checkedPlanes.indexOf(thexID[1]);
			//console.log('index : '+index);
			if (index > -1) {
				this.checkedPlanes.splice(index, 1); 
			}
	    } else {
			var thexID = event.id.split("-");
			const index = this.checkedPlanes.indexOf(thexID[1]);
			//console.log('index : '+index);
			if (index == -1) {
				this.checkedPlanes.push(thexID[1]);
			}
		}

		//console.log(this.checkedPlanes);

		let compareParamsSet = '';
		for (let j = 0; j < this.checkedPlanes.length; j++) {
			//let esteID = this.checkedPlanes[j].split("-");
			compareParamsSet += this.checkedPlanes[j]+',';
			//console.log('compareParamsSet : '+compareParamsSet);
			$('#compareParamsSet').val(compareParamsSet);
		} 

	}

	getFilterPlanes(params) {

		/* 
		let listaPlanes = $("#ecommerce-products input:checkbox:checked");
		for (let i = 0; i < listaPlanes.length; i++) {
			 let TagCheckes = document.getElementById(listaPlanes[i].id);
             let idcheck = TagCheckes.id;
			 //this.checkedPlanes.push(idcheck);
			 if (this.checkedPlanes.length == 0) {
				this.checkedPlanes.push(idcheck);
			 } else {
				let selFound = '0';
				for (let j = 0; j < this.checkedPlanes.length; j++) {
				   if (this.checkedPlanes[j] == idcheck) {
					   selFound = '1';
				   }
				   if (selFound == '0') {
					   this.checkedPlanes.push(idcheck);
				   }
				}
			 }
             //document.getElementById(idcheck).click();
		}
		*/
		//console.log(this.checkedPlanes);
		let paramsChecked = $('#compareParamsSet').val();

		//for (let j = 0; j < this.checkedPlanes.length; j++) {
		//	var valores = this.checkedPlanes[j].split("-");
		//	paramsChecked += valores[1]+',';
		//}

		//this.clearSelected();
		//console.log('clearSelected!');

		let varOrder1 = '';
		let varOrder2 = '';
		let Tagordena = (<HTMLInputElement>document.getElementById('ordena')).value;
		//console.log('Tagordena : '+Tagordena);
		if (Tagordena != '') {
			//console.log('entre en Tagordena');
		   this.ordena = Number(Tagordena);
	    }
		//console.log('getFilterPlanes ordena: '+this.ordena);
	
		//console.log('params : '+params);
		//$( "#dataCall" ).trigger( "click" );
		//return false;
		//this.funcParan = params;
		//console.log(this.funcParan);
		//return false;
		params = params + '|C--' + paramsChecked;
		//console.log('params : '+params);

		this.planesService.getData(params).subscribe(  
			(data: any[]) => {
				//console.table(data);
				//this.planes = data.sort();  
				this.planes = data;  
				//if (this.order == 'I') { varOrder1 = 'a.isapre'; varOrder2 = 'b.isapre'; }
				//if (this.order == 'Z') { varOrder1 = 'a.zona'; varOrder2 = 'b.zona'; }
				//if (this.order == 'T') { varOrder1 = 'a.tipo'; varOrder2 = 'b.tipo'; }
				//if (this.order == 'V') { varOrder1 = 'a.base'; varOrder2 = 'b.base'; }

				//this.planes = data.sort((a, b) => (varOrder1 > varOrder2) ? -1 : 1);
				//console.log('ordena : '+this.ordena);
				if (this.ordena == 0) {
					this.planes.sort((n1,n2) => n1.compara - n2.compara);
					//console.log('Menor');
			    } else {
					if (this.ordena == 1) {	
						this.planes.sort((n1,n2) => n2.compara - n1.compara);
						//console.log('Mayor');
					} else {
						//console.log('Ninguno'); 
					}
			    }   


                //console.log('planes : '+this.planes.length);
				let numero = this.planes.length;
				$("#numPlanes").html( numero.toLocaleString('de-DE') );
				this.pagina = true;
			 }, 
				error => console.log('getFilterPlanes params : '+params+' Error : '+JSON.stringify(error)) 
			);
			$('#products').removeClass('hidden');
			$('#loaderThis').addClass('hidediv');
			$('#loaderThis').removeClass('showdiv');

		/* 
		listaPlanes = $("#ecommerce-products input:checkbox");
		for (let i = 0; i < listaPlanes.length; i++) {
			 let TagCheckes = document.getElementById(listaPlanes[i].id);
             let idcheck = TagCheckes.id;
			 for (let j = 0; j < this.checkedPlanes.length; j++) {
				 if (this.checkedPlanes[j] == idcheck) {
					//document.getElementById(idcheck).click();
					$('#'+idcheck).attr('checked',true);
					$('#'+idcheck).prop('checked',true);
				 }
		     }
			 //this.checkedPlanes.push(idcheck);
             //document.getElementById(idcheck).click();
		}
		*/





		}



}
