import { Component, OnInit, ElementRef } from '@angular/core';
import { TiposService } from '../tipos.service';
import { AppPlanes } from './app.planes';

@Component({
  selector: 'filter-tipo',
  templateUrl: './filter.tipo.html'
})

export class FilterTipo {

  textoprop = 'Planes Libre Elección: Te otorgan cobertura en cualquier clínica u hospital del país. <br><br> Planes Preferentes: Te otorgan cobertura en un o más clínicas y obtienes altos porcentajes de cobertura SIN TOPE. Además, estos planes cuentan con cobertura Libre Elección.  Planes Cerrados Te otorgan cobertura cobertura si solo te atiendes en las clínicas u hospitales que indica el plan de salud. No tienes alternativa de cobertura en Libre Elección.';

  callTipoSolo() {
    //alert('Call callTipoSolo!');
  }

  Ltipos = null;
  
  Ltipo = [{ id: null, nombre: null, planes: null }]

    constructor(private tiposServicio: TiposService, private el: ElementRef, public planesApp: AppPlanes) {}
  
    ngOnInit() {
      this.recuperarTodos();
    }
  
    recuperarTodos() {
      this.tiposServicio.recuperarTodos().subscribe(result => {
        this.Ltipos = result;
        //console.table(this.Ltipos);       
        this.Ltipos.splice(2, 1);
        //console.log(this.Ltipos);
        
        //this.Ltipos.forEach((data: any) => {
        //  if (data.id == '1') { data.nombre = 'Preferente / LE'; } 
        //});

      }
     
      );
      
    }
   
    hayRegistros() {
      return true;
    } 

    showSoloTipo(el) {
      let Tagshow = document.getElementById('showSolotipo-'+el);
      let Taghide = document.getElementById('showPlanestiposolo-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showTodoTipo(el) {
      let Tagshow = document.getElementById('showTodotipo-'+el);
      let Taghide = document.getElementById('showPlanestipotodo-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showPlanesSoloTipo(el) {
      let Tagshow = document.getElementById('showPlanestiposolo-'+el);
      let Taghide = document.getElementById('showSolotipo-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showPlanesTodoTipo(el) {
      let Tagshow = document.getElementById('showPlanestipotodo-'+el);
      let Taghide = document.getElementById('showTodotipo-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }
    

/* 
    filtroTipo(event) {
      var filtroCheck = '';

      [].forEach.call(
        document.querySelectorAll('input, checkbox'), 
        function(e) { 
          var str = e;
          if (str.type == 'checkbox') {
            var n = str.id.indexOf("Tipo-");
            if (n == 0) {
              if (str.checked == true) {
                var checkId = str.id;
                var porciones = checkId.split('-');
                filtroCheck = filtroCheck + porciones[1] + ',';
              }
            }  
            }
         }
      );
    }

*/

    filtroTipo(event) {
      this.planesApp.SendFilterPlanes();
      //var filtroCheck = 'I-'; 

      /* 
      var filtroCheck = '';

      [].forEach.call(
        document.querySelectorAll('input, checkbox'), 
        function(e) { 
          var str = e;
          //console.log('str : '+str.type);
          if (str.type == 'checkbox') {
            var n = str.id.indexOf("tipo-");
            if (n == 0) {
              //console.log('id : '+str.id+' - checked : '+str.checked);
              if (str.checked == true) {
                var checkId = str.id;
                var porciones = checkId.split('-');
                filtroCheck = filtroCheck + porciones[1] + ',';
              }
            }  
          }
        }
      );
      if (filtroCheck !== '') {
         filtroCheck = filtroCheck.slice(0, -1)
         filtroCheck = 'T-'+filtroCheck;
      } else {
         filtroCheck = 'A';
      }
      //console.log('filtroCheck : '+filtroCheck);
      this.planesApp.SendFilterPlanes(filtroCheck);
      */
    }




}
