<!--<div class="sidebar-shop">-->
   <div class="row d-block d-sm-none"><br><br><br><br></div>
   <!--<div class="row">
      <div class="col-sm-12">  
         <div class="alert alert-warning" role="alert">
            <div class="alert-body text-center">
               UF: {{valorUF}}
               <input type="text" class="hidden" name="UFvalor" id="UFvalor" value="{{valorUF}}" />
            </div>
         </div>
      </div> 
   </div>-->

   <!--<div class="card">
      <div class="card-body">    
         <div class="row">
            <div class="col-sm-12 col-md-12">  
               <div class="alert alert-warning small" role="alert">
                  <div class="alert-body text-center">
                     <h5 id="lauf" class="text-warning"><strong>UF: {{valorUF}}</strong></h5>
                     <input type="text" class="hidden" name="UFvalor" id="UFvalor" value="{{valorUF}}" />
                  </div>
               </div>
            </div> 
            <div class="col-sm-12 col-md-12">   
               <div class="alert alert-primary" role="alert"> 
                  <div class="alert-body text-center">
                     <h5 class="text-primary"><strong><i data-feather="file-text" class="font-medium-3 "></i>&nbsp;&nbsp;&nbsp;<span id="numPlanes"></span> planes encontrados</strong></h5>
                  </div>
               </div>
            </div> 
         </div>  
      </div>
   </div>  -->

   <div class="card" style="background-color: lightcyan">
      <div class="card-body">  
         <!-- Price Filter starts -->

         <div class="multi-range-price">
            <div class="alert alert-danger" role="alert">  
               <div class="alert-body text-center"> 
                  Ingresa tu edad y la de cada beneficiario <i data-feather="info" data-toggle="popover" data-content="Debes ingresar edad de cada beneficiario, cotizante(s) y carga(s) para poder calcular el precio final de tu plan de salud." data-trigger="hover" data-original-title="Beneficiarios" class="font-medium-3 text-muted"></i>
               </div>
            </div>
            <div class="row">
               <div class="form-group col-md-6"><!-- data-toggle="popover" data-content="Debes ingresar edad de cada beneficiario, cotizante(s) y carga(s) para poder calcular el precio final de tu plan de salud." data-trigger="hover" data-original-title="Beneficiarios"  -->
                  <div class="cart-item-qty" class="font-medium-3 text-muted">
                     <div class="input-group">
                        <!--<input class="touchspin-cart" name="name" #personEdad type="number" value="0">-->
                        <input class="form-control" id="personEdad" name="personEdad" #personEdad type="number" oninput="if(value.length>3)value=value.slice(0,3)" autocomplete="off" value="" placeholder="edad">
                     </div>
                  </div>
               </div>
               <div class="form-group col-md-6">
                  <div class="custom-control custom-switch custom-control-inline">
                     <input (change)="tipoChange()" type="checkbox" class="custom-control-input" name="name" #personTipo id="customSwitch1" />
                     <label class="custom-control-label" for="customSwitch1" id="labelswitch">Cotizante</label>
                  </div>
               </div>
            </div>
            <button type="button" (click)="filtroPerson()" class="btn btn-relief-danger btn-block pulsingButton"><i data-feather="user-plus" class="mr-25"></i><span>Agregar Beneficiario</span></button>
            <hr>
            <div class="table-responsive">
               <table class="table-striped small" width="100%" id="personList">
                  <thead>
                     <tr class="small bg-light">
                        <th width="20%">Edad</th>
                        <th width="20%">Factor</th>
                        <th width="50%">Tipo</th>
                        <th width="10%"></th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let person of myObjArray;" id="fila-{{person.id}">
                        <td>{{person.edad}}</td>
                        <td>{{person.factor}}</td>
                        <td><span [ngClass]="person.tipo == 'Cotizante' ? 'badge badge-light-success' : 'badge badge-light-danger'">{{person.tipo}}</span></td>
                        <td (click)="deletePerson($event.target)"><button type="button" id="line-{{person.id}}" class="btn btn-icon btn-icon rounded-circle btn-flat-danger">x</button></td>
                     </tr>
                  </tbody>
               </table>
               <hr>
               <div class="alert alert-danger" role="alert">
                  <div class="alert-body">Total factores : <span id="sumFactor">{{sumaFactores}}</span></div>
               </div>

               <div class="text-center"><div class="badge badge-light-dark">UF: {{ valorUF }}</div>
               <input type="text" class="hidden" name="UFvalor" id="UFvalor" value="" />     </div>         
                        <!--<div class="alert alert-warning small" role="alert">  
                           <div class="alert-body text-center">
                              <p id="lauf" class="text-warning">UF: {{valorUF}}</p>
                              <input type="text" class="hidden" name="UFvalor" id="UFvalor" value="" />
                           </div>
                        </div>-->

            </div>



            <!--<div class="table-responsive">
               <table class="small" width="100%" id="factor">
                  <thead>
                     <tr class="small">
                        <th>Edad</th>
                        <th>Factor</th>
                        <th>Tipo</th>
                        <th></th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let Lfactor of Lfactores" id="{{Lfactor.id}}">
                        <td>{{Lfactor.desde}}</td>
                        <td>{{Lfactor.hasta}}</td>
                        <td>{{Lfactor.cotizante}}</td>
                        <td><a><i data-feather="delete" class="mr-50 text-danger"></i></a></td>
                     </tr>
                  </tbody>
               </table>
               <hr>
               <div class="alert alert-primary" role="alert">
                  <div class="alert-body">Total factores : 2.18</div>
               </div>
            </div>-->




         </div>
      </div>
   </div>
<!--</div>-->