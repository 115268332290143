       <!-- Ecommerce Sidebar Starts -->
       <!--<div class="sidebar-shop">-->
          <div class="card">
             <div class="card-body">
                <div class="brands">
                   <div class="alert alert-primary" role="alert">
                      <div class="alert-body text-center">
                         ¿Cuanto quieres pagar por tu plan? <i data-feather="info" data-toggle="popover" data-content="Ingresa un monto mínimo y máximo y presiona el botón aplicar precio. El sistema te mostrará planes que se ajusten a los montos elegidos." data-trigger="hover" data-original-title="¿Cuanto quieres pagar por tu plan?" class="font-medium-3 text-muted"></i>
                      </div>
                   </div>
                   <div class="price-slider">
                      <div class="price-slider">
                         <div class="range-slider mb-2">
                            <input type="text" class="js-range-slider " value="" />
                            <div class="d-none" id="cambioNumero" (click)="filtroAmount()"></div>
                         </div>
                         <form id="AmountList">
                         <div class="row">
                            <div class="col-md-6 col-sm-12 mb-2"> 
                               <label for="numeral-from">Mínimo</label> 
                               <input type="text" class="form-control numeral-mask js-input-from" id="numeralfrom" #numeralfrom (change)="filtroAmount()"/>
                            </div>
                            <div class="col-md-6 col-sm-12 mb-2">
                               <label for="numeral-to">Máximo</label>
                               <input type="text" class="form-control numeral-mask js-input-to" id="numeralto" #numeralto  (change)="filtroAmount()"/>
                            </div>
                         </div>
                        </form>
                        <!---<button id="amountClick" type="button" (click)="filtroAmount()" class="btn btn-primary btn-block"><i data-feather="filter" class="mr-25"></i><span>Aplicar Precio</span></button>-->

                        <!---<div class="alert alert-warning small" role="alert">  
                           <div class="alert-body text-center">
                              <p id="lauf" class="text-warning">UF: {{valorUF}}</p>
                              <input type="text" class="hidden" name="UFvalor" id="UFvalor" value="" />
                           </div>
                        </div>-->
               

                         <!--<div class="extra-controls">-->
                            <!--<input type="text" class="js-input-from" value="0" /> -->
                            <!--<input type="text" class="js-input-to" value="0" /> -->
                         <!--</div>-->
                      </div>
                   </div>
                </div>
             </div>
          </div>
       <!--</div>-->
       <!-- Ecommerce Sidebar Ends -->
