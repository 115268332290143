<!--<div class="row">
   <div class="col-sm-12 col-md-6">  
      <div class="alert alert-warning" role="alert">
         <div class="alert-body text-center">
            <h5 id="lauf" class="text-warning"><strong>UF: {{valorUF}}</strong></h5>
            <input type="text" class="hidden" name="UFvalor" id="UFvalor" value="{{valorUF}}" />
         </div>
      </div>
   </div> 
   <div class="col-sm-12 col-md-6">   
      <div class="alert alert-primary" role="alert"> 
         <div class="alert-body text-center">
            <h5 class="text-primary"><strong><i data-feather="file-text" class="font-medium-3 "></i>&nbsp;&nbsp;&nbsp;<span id="numPlanes"></span> planes encontrados</strong></h5>
         </div>
      </div>
   </div> 
</div>-->
<section id="ecommerce-products" class="list-view" style="top:0;">

   <div *ngIf="isMobile();then content else other_content"></div>      
   <ng-template #content> 
      <div id="messageCard1" class="card text-center shadow-none " style="background-color: lightcyan ;"> 
         <div class="card-body" style="background-image: url(assets/app-assets/images/elements/arrow.png); background-repeat: no-repeat; background-size: contain; padding-top: 10px; padding-bottom: 10px;">
            <h4 style="padding-top: 0px;">Para calcular el valor final de tu plan...</h4>
            <span class="text-body">Debes ingresar edad de cada beneficiario (titular y cargas).</span><br><br>
            <button type="button" class="btn btn-danger btn-sm" (click)="viewToggler1()"><i class="far fa-filter"></i> Ingresar Perfil</button>
         </div>
      </div>  

   </ng-template>
   <ng-template #other_content>
      <div id="messageCard" class="card text-center shadow-none " style="background-color: lightcyan ;">
         <div class="card-body" style="background-image: url(assets/app-assets/images/elements/arrow.png); background-repeat: no-repeat; background-size: contain; padding-top: 10px; padding-bottom: 10px;">
            <h4 style="padding-top: 0px;">Para calcular el valor final de tu plan...</h4>
            <span class="text-body">Debes ingresar edad de cada beneficiario (titular y cargas).</span><br><br>
            <button type="button" (click)="msgClose()" class="btn btn-danger btn-sm"><i class="far fa-eye-slash"></i> Ocultar</button>
         </div>
      </div>
   
   </ng-template>   

   <!--<img src="https://common.images.eremates.cl/pcanas.png" width="160px">--> 

   <div class="containermidle bg-light" id="loaderThis">
      <div class="d-flex justify-content-center" style="margin-top: 10%;"> 
         <img src="assets/app-assets/loader.gif"> 
      </div>
   </div>
   <div id="dataCall" (click)="callFilter()" role="" class="hidden"></div>  
   <!--<form id="PlanesLista">-->
 
   <div style="top: 0%;" *ngFor="let plan of planes | paginate: { itemsPerPage: tableSize, currentPage: page, totalItems: count } let i = index trackBy: itemsTrackedBy" class="card ecommerce-card mt-0">
      <div class="text-center">  
         <br>
         <img src="https://common.tu7.cl/isapres/{{ plan.logo }}" width="160px">
         <br><br>
         <div class="text-center">
            <span class="card-text mt-2"><a href="javascript:void(0)" class="company-name">({{ plan.codigo }})</a></span>
            <br><br><div class="badge badge-light-dark">Tipo de Plan : {{ plan.tipo }}</div>
            <!--&nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-glow badge-success"><small>Valor Base: </small>{{ plan.base }}</span>--><br><br>
         </div>
         <!--
         <div style="margin-left: 5%; margin-right: 5%; margin-top: 5%;">
            <div class="alert alert-warning" role="alert">
               <div class="alert-body">
                  <div class="custom-control custom-checkbox">
                     <input type="checkbox" class="custom-control-input" [(ngModel)]="plan.ischecked" id="seleccion-{{ plan.id }}" (change)="checkValue($event.target)" />
                     <label class="custom-control-label" for="seleccion-{{ plan.id }}"><strong>Seleccionar este plan</strong></label>
                     &nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-glow badge-success">{{ plan.base }}</span> // no mostrar
                   </div>
                  <strong>{{ plan.isapre }}</strong>&nbsp;&nbsp;&nbsp;&nbsp; // no mostrar
                  <div class="badge badge-glow badge-success">{{ plan.base }}</div> // no mostrar
               </div>
            </div>
         </div>
         -->
      </div>
      <div class="card-body swiper-container">
         <div class="item-wrapper">
            <div class="item-rating">
               <h4 class="item-price"><strong>UF {{ plan.planUF }}</strong></h4>
            </div>
            <div>
               <h4 class="item-price">$ {{ plan.planPesos }}</h4>
            </div>
         </div>
         <div class="row">
            <div class="col-md-12">
               <div class="alert alert-dark" role="alert">
                  <div class="alert-body text-center">
                     <strong>{{ plan.nombre }}</strong>
                  </div>
                </div>
               <!--<h6 class="item-name">
                  <a class="text-body" href="javascript:void(0)">{{ plan.nombre }}</a>
                 <span class="card-text item-company"><a href="javascript:void(0)" class="company-name">({{ plan.codigo }})</a></span>
               </h6>-->
            </div>
            <div class="col-md-6">
               <!--<div class="col-md-6" ngbPopover="{{ plan.hospitalaria }}" popoverTitle="COBERTURA HOSPITALARIA" popoverClass="item-description" container="body">-->
               <p class="card-text item-description" matTooltip="Info about the action">
                  <strong><i class="fas fa-procedures font-18 me-2 text-danger"></i> COBERTURA HOSPITALARIA</strong><br>
                  <small class="text-muted" [innerHTML]="plan.hostext1"></small>
                  <!--<small class="text-muted">{{plan.hospitalaria}}</small>-->
               </p>
            </div>
            <div class="col-md-6">
               <p class="card-text item-description">
                  <strong><i class="fas fa-user-md font-18 me-2 text-danger"></i> COBERTURA AMBULATORIA</strong><br>
                  <small class="text-muted" [innerHTML]="plan.ambtext1"></small>
                  <!--<small class="text-muted">{{plan.ambulatoria}}</small>-->
               </p>
            </div>
         </div>
      </div>  
      <div class="item-options text-center">
         <div class="item-wrapper">
            <div class="item-cost">
               <div *ngIf="plan.blanco=='x';then showdesde else hidedesde"></div>
               <ng-template #showdesde><div class="badge badge-light-dark lomuestro">Desde</div></ng-template>
               <ng-template #hidedesde></ng-template>
               
               <h4 class="item-price text-danger"><strong>UF {{ plan.planUF }}</strong></h4>
               <h4 class="item-price text-danger">$ {{ plan.planPesos }} /mes</h4> 
            </div>
         </div>
         <br>
         <a href="javascript:void(0)" (click)="viewPlan(plan.planPdf,plan.nombre,plan.isapre,plan.id)" class="btn btn-block btn-outline-danger rounded-pill">
            <span><i class="far fa-eye"></i> Ver Plan</span> 
         </a>
         <br>
         <a id="pdf-{{ plan.id }}" class="hidden" download href="https://www.pdfplan.cl/viewplan/{{ plan.planPdf }}"></a>
         <a href="javascript:void(0)" (click)="estePlan(plan.id)" class="btn btn-block btn-relief-danger mt-2 rounded-pill pulsingButtonSearch">
            <span><i class="far fa-thumbs-up"></i> Quiero este plan!</span> 
         </a>
      </div>
   </div>
   <!--</form>-->
</section> 
<pagination-controls *ngIf="pagina" nextLabel="Siguiente" previousLabel="Anterior" (pageChange)="onTableDataChange($event)" class="pagination justify-content-center mt-2"></pagination-controls>
<div class="modal fade" id="planViewer" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="pdfLabel"></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
         <div class="modal-body" id="pdfData"></div>
      </div>
   </div> 
</div>   
<a href="javascript:void(0)" class="hidden shop-sidebar-toggler"></a>
<div id="sisi" class="hidden"></div>
<div class="buy-now d-block d-sm-none"><a href="javascript:void(0)" id="toggle-view" (click)="viewToggler()" class="btn btn-danger btn-block"><span></span><i data-feather='filter'></i> Ingresar Perfil</a><a href="javascript:void(0)" id="toggle-close" (click)="closeToggler()" class="hidden btn btn-danger btn-block"><span></span><i data-feather='filter'></i> Ocultar Filtros</a></div>