import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UfService {

  //url='https://tu7porciento.cl/dataMining/getUFday.php'; 
  url='https://apivendor.tu7.cl/getUF.php';  
  urlTable='https://apivendor.tu7.cl/getUfTable.php';

  constructor(private http: HttpClient) { }

  recuperarUF() {
    return this.http.get(this.urlTable); 
    //return this.http.get(this.url); 
    //return this.http.get(`${this.url}getUFday.php`);   


  }
  
} 
