<nav class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center" data-nav="brand-center">
   <div class="navbar-header d-xl-block d-none">
      <ul class="nav navbar-nav">
         <li class="nav-item">
            <a class="navbar-brand" href="javascript:void(0)">
               <!--<h2 class="brand-text mb-0">tu7porciento.cl</h2>-->
               <img class="" src="assets/app-assets/images/Mas_Vida.png" alt="avatar" width="50%">
            </a>
         </li>
      </ul>
   </div>
   <div class="navbar-container d-flex content">
      <div class="bookmark-wrapper d-flex align-items-center">
         <div class="user-nav d-sm-flex ">
            <span class="user-name font-weight-bolder">Cotizador de planes Nueva Masvida</span>
         </div>

         <!--<ul class="nav navbar-nav d-xl-none">
            <li class="nav-item"><a class="nav-link menu-toggle" href="javascript:void(0);"><i class="ficon" data-feather="menu"></i></a></li>
         </ul>-->
      </div>
      <ul class="nav navbar-nav align-items-center ml-auto">
         <li class="nav-item dropdown dropdown-user">
            <a class="" id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <!--<div class="user-nav d-sm-flex d-none">
                  <span class="user-name font-weight-bolder">Cotizantes</span>
                  <span class="user-status">Planes de Salud</span>
               </div>-->

               <div class="">
                  <img class="" src="assets/app-assets/images/ico/favicon.png" alt="" width="35px">
               </div>
    

            </a>
         </li>
      </ul>
   </div>
</nav>
<div class="app-content content ecommerce-application">  
   <div class="content-wrapper container p-0">

      <!--<div class="alert alert-info alert-dismissible fade show" role="alert">
         <div class="alert-body">
            Bienvenido a tu7porciento.cl
         </div>
         <button type="button" class="close" data-dismiss="alert" aria-label="Close">
           <span aria-hidden="true">&times;</span>
         </button>
       </div>-->


      <!--<div class="content-header row">
         <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
               <div class="col-12">
                  <h2 class="content-header-title float-left mb-0">Cotizador</h2>
                  <div class="breadcrumb-wrapper">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item">Planes
                        </li>
                        <li class="breadcrumb-item">Isapres
                        </li>
                     </ol>
                  </div>
               </div>
            </div>
         </div>
      </div>-->
      <div class="content-detached content-right">
         <div class="content-body">

         

            <app-planes-header></app-planes-header>
            <div class="body-content-overlay"></div>
            <app-planes-search></app-planes-search>
            <app-planes></app-planes>
         </div>
      </div>
      <app-filtros></app-filtros> 
   </div>
</div>
<div class="sidenav-overlay"></div>
<div class="drag-target"></div>
<!--<footer class="footer footer-light">
   <p class="clearfix mb-0"><span class="float-md-left d-block d-md-inline-block mt-25">COPYRIGHT  &copy; 2021<a class="ml-25" href="https://www.tu7porciento.cl" target="_blank">tu7porciento.cl</a><span class="d-none d-sm-inline-block">, Derechos reservados</span></span></p>
</footer>-->
<button id="toTop" class="btn btn-danger btn-icon scroll-top" type="button"><i data-feather="arrow-up"></i></button>
<router-outlet></router-outlet>