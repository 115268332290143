       <!--<div class="sidebar-shop">-->
          <div class="card">
             <div class="card-body">
                <div class="brands">
                   <div class="alert alert-primary" role="alert"> 
                      <div class="alert-body text-center"> 
                        Elige tipo de plan <i data-feather="info" data-toggle="popover" data-content="Puedes elegir entre 2 tipos de planes (Preferente que incluye Libre Elección o Cerrado) para buscar el que más se acomode a tus necesidades." data-trigger="hover" data-original-title="Elige tipo de plan" class="font-medium-3 text-muted"></i>
                      </div>
                   </div>
                   <form id="TiposList">
                   <ul class="list-unstyled brand-list">
                      <li *ngFor="let Ltipo of Ltipos">
                         <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="tipo-{{ Ltipo.id }}" checked name="tipo-{{ Ltipo.id }}" (change)="filtroTipo($event.target)">
                            <!--<input type="checkbox" class="custom-control-input" id="tipo-{{ Ltipo.id }}" checked="">-->
                            <label class="custom-control-label" for="tipo-{{ Ltipo.id }}">{{ Ltipo.nombre }}</label>
                         </div>
                         <div id="soloTipoID-{{ Ltipo.id }}" class="demo-spacing-0 text-center" style="float: right; width: 60px; cursor: pointer;">
                            <div class="alert alert-primary small" role="alert">
                               <div class="alert-body" class="text-danger" (mouseover)="showSoloTipo(Ltipo.id)" (mouseout)="showPlanesSoloTipo(Ltipo.id)"><span id="showPlanestiposolo-{{ Ltipo.id }}" class="">{{ Ltipo.planes }}</span> <span (click)="planesApp.soloTipoClick(Ltipo.id) " id="showSolotipo-{{ Ltipo.id }}" class="hidden text-danger">SOLO</span> </div>
                            </div>
                         </div>
                         <div id="todoTipoID-{{ Ltipo.id }}" class="demo-spacing-0 text-center hidden" style="float: right; width: 60px; cursor: pointer;">
                           <div class="alert alert-primary small" role="alert">
                              <div class="alert-body" class="text-danger" (mouseover)="showTodoTipo(Ltipo.id)" (mouseout)="showPlanesTodoTipo(Ltipo.id)"><span id="showPlanestipotodo-{{ Ltipo.id }}" class="">{{ Ltipo.planes }}</span> <span (click)="planesApp.todoTipoClick(Ltipo.id) " id="showTodotipo-{{ Ltipo.id }}" class="hidden text-danger">TODOS</span> </div>
                           </div>
                        </div>
                     </li>
                   </ul>
                  </form>
                </div>
             </div>
          </div>
       <!--</div>-->
 