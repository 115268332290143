<section id="ecommerce-header">
   <input type="hidden" id="ordena" value=""> 
   <input type="hidden" id="compareParamsSet" value="">
   <input type="hidden" id="prestadores" value="">

   <div class="card">
      <div class="d-flex justify-content-between align-items-center mx-50 row pt-0 pb-2">
         <div class="col-md-4">
            <div class="input-group input-group-merge mt-1"> 

               <a class="mt-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
               <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (ngModelChange)="listChage()" style="margin-top: 5px; overflow-y:scroll; overflow-x: hidden; max-height: 200px; width: 100%;">
                 <a class="dropdown-item" href="javascript:void(0)" (click)="setSearchText(ListResult.prestador)" *ngFor="let ListResult of listResultDataSelect">{{ListResult.prestador}}</a>
               </div>

               <span (click)="appPlanesSearch.clearSearchText()" class="input-group-text bg-light"><i data-feather="eye-off" style="cursor: pointer;" class="text-muted"></i> </span>
               <input type="text" class="form-control search-product bg-light" id="plan-search" (keyup)="appPlanesSearch.setSearchText(); getSearchList();" placeholder="Buscar en planes" aria-label="Buscar..." aria-describedby="shop-search">

               <div class="input-group-append">
                  <span (click)="appPlanesSearch.setSearchText()" class="input-group-text bg-light"><i data-feather="search" style="cursor: pointer;" class="text-muted pulsingButtonSearch"></i> </span>
               </div>



               <!--
               <span (click)="appPlanesSearch.clearSearchText()" class="input-group-text bg-light"><i data-feather="eye-off" style="cursor: pointer;" class="text-muted"></i> </span>
               <input type="text" class="form-control search-product bg-light" id="plan-search" placeholder="Buscar en planes" aria-label="Buscar..." aria-describedby="shop-search">
               <div class="input-group-append">
                  <span (click)="appPlanesSearch.setSearchText()" class="input-group-text bg-light"><i data-feather="search" style="cursor: pointer;" class="text-muted pulsingButtonSearch"></i> </span>
               </div>
               -->
            </div> 
        </div>
        <!--
         <div class="col-md-8">
            <div class="btn-group btn-block" role="group" aria-label="">
               <button type="button" (click)="CompareSelected()" class="btn btn-relief-primary mt-1 btn-block"><i data-feather='grid'></i> Comparar</button>
               <button type="button" (click)="clearSelected()" class="btn btn-relief-warning mt-1 btn-block"><i data-feather='eye-off'></i> Limpiar</button>
            </div>
         </div>
        -->


        <!--<div class="col-md-2"><button (click)="CompareSelected()" type="button" class="btn btn-relief-primary btn-block mr-1 mt-1"><i data-feather='grid'></i> Comparar</button></div>-->
        <!--<div class="col-md-2"><button (click)="clearSelected()" type="button" class="btn btn-relief-warning btn-block mr-1 mt-1"><i data-feather='eye-off'></i> Limpiar</button></div>-->
        <!--<div class="col-md-2 d-none d-sm-block">-->
         <div class="col-md-2 text-center">

            <div class="btn-group dropdown-sort btn-block mt-1"> 
               <button type="button" class="btn btn-outline-danger dropdown-toggle btn-block mr-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <span class="active-sorting">Ordenar por</span>
               </button>
               <div class="dropdown-menu">
                  <a class="dropdown-item" href="javascript:void(0);" (click)="orderPlan('1')"><i class="fas fa-sort-numeric-up-alt"></i>&nbsp;&nbsp;&nbsp;Mayor valor</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="orderPlan('0')"><i class="fas fa-sort-numeric-down"></i>&nbsp;&nbsp;&nbsp;Menor Valor</a>
               </div>
           </div>
        </div>
        <div class="col-md-2 d-none d-sm-block">
         <div class="btn-group btn-group-toggle mt-1" data-toggle="buttons">
            <label class="btn btn-icon btn-outline-primary view-btn list-view-btn" (click)="listClick($event)">
            <input type="radio" name="radio_options" id="radio_option2"checked="">
            <i data-feather="list" class="font-medium-3"></i>
            </label>
            <label class="btn btn-icon btn-outline-primary view-btn grid-view-btn" (click)="gridClick($event)">
            <input type="radio" name="radio_options" id="radio_option1">
            <i data-feather="grid" class="font-medium-3"></i>
            </label>
         </div>

        </div>
        
      </div>
    </div>
   



    <!--<div class="row">
       <div class="col-sm-12">
          <div class="ecommerce-header-items">
             <div class="result-toggler">
                <button class="navbar-toggler shop-sidebar-toggler" type="button" data-toggle="collapse">
                <span class="navbar-toggler-icon d-block d-lg-none"><i data-feather="menu"></i></span>
                </button>
             </div>
             <input type="hidden" id="ordena" value="0"> 
             <input type="hidden" id="compareParamsSet" value="">
             <input type="hidden" id="prestadores" value="">
             
             <div class="demo-inline-spacing">
               <button (click)="CompareSelected()" type="button" class="btn btn-relief-primary mr-1 "><i data-feather='grid'></i> Comparar</button>
               <button (click)="clearSelected()" type="button" class="btn btn-relief-warning mr-1"><i data-feather='eye-off'></i> Limpiar</button>
               <div class="btn-group dropdown-sort"> 
                   <button type="button" class="btn btn-outline-primary dropdown-toggle mr-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   <span class="active-sorting">Ordenar por</span>
                   </button>
                   <div class="dropdown-menu">
                      <a class="dropdown-item" href="javascript:void(0);" (click)="orderPlan('1')"><i class="fas fa-sort-numeric-up-alt"></i>&nbsp;&nbsp;&nbsp;Mayor valor</a>
                      <a class="dropdown-item" href="javascript:void(0);" (click)="orderPlan('0')"><i class="fas fa-sort-numeric-down"></i>&nbsp;&nbsp;&nbsp;Menor Valor</a>
                   </div>
               </div>
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                   <label class="btn btn-icon btn-outline-primary view-btn list-view-btn">
                   <input type="radio" name="radio_options" id="radio_option2"checked="">
                   <i data-feather="list" class="font-medium-3"></i>
                   </label>
                   <label class="btn btn-icon btn-outline-primary view-btn grid-view-btn">
                   <input type="radio" name="radio_options" id="radio_option1">
                   <i data-feather="grid" class="font-medium-3"></i>
                   </label>
                </div>
             </div>
          </div>
       </div>
    </div>-->
 </section>

 <div class="modal fade" id="CompareViewer" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title text-center" id="CompareLabel"><i data-feather='grid'></i> Comparativo Planes de Salud</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body" id="CompareData">
            <table class="table table-bordered table-hover table-striped table-responsive">
               <thead>
                  <tr>
                     <th align="center">Plan</th>
                     <th align="center">Isapre</th>
                     <th align="center">Tipo Plan</th>
                     <th align="center">Valor Base</th>
                     <th align="center">Cobertura Hospitalaria</th>
                     <th align="center">Cobertura Ambulatoria</th>
                     <th align="center">Valor UF</th>
                     <th align="center">Valor Pesos</th>
                     <th align="center"></th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let Cplan of Cplanes">
                     <td>
                        <span class="card-text mt-2"><a href="javascript:void(0)" class="company-name">{{ Cplan.codigo }}</a></span>
                        <br><code>{{ Cplan.nombre }}</code>
                     </td>
                     <td><img src="https://tu7porciento.cl/dev.cotizantes/assets/app-assets/isapres/{{ Cplan.logo }}" width="100px"></td>
                     <td align="center">
                        <div class="badge badge-light-dark">{{ Cplan.tipo }}</div>
                     </td>
                     <td align="center" class="table-warning"><code>{{ Cplan.base }}</code></td>
                     <td align="justify">{{ Cplan.hospitalaria }}</td>
                     <td align="justify">{{ Cplan.ambulatoria }}</td>
                     <td align="center">
                        <h4>{{ Cplan.planUF }}</h4>
                     </td>
                     <td align="center" nowrap>
                        <h4>$ {{ Cplan.planPesos }}</h4>
                     </td>
                     <td>
                        <div class="custom-control custom-control-success custom-checkbox">
                           <input type="checkbox" class="custom-control-input" id="{{ Cplan.id }}" checked />
                           <label class="custom-control-label" for="{{ Cplan.id }}"></label>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="modal-footer">  
            <button (click) = "triggerSelected()" type="button" class="btn btn-primary pulsingButton"><i data-feather='user'></i> Enviar Seleccionados a Ejecutivo</button>
            <button type="button" class="btn btn-warning" data-dismiss="modal"><i data-feather='x-circle'></i> Cerrar</button>
         </div>
      </div>
   </div>
</div>

<div class="modal fade text-left modal-dange" id="selectedMail" tabindex="-1" role="dialog" aria-labelledby="myModalLabel33" aria-hidden="true">
   <div class="modal-dialog  modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel33"><i data-feather='user-check'></i> Solicitar Contacto de Ejecutivo</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
         </div>
            <div class="modal-body">
               <form class="form">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="nombreInput">Nombre</label>
                        <input type="text" id="nombreInput" class="form-control" placeholder="Ingrese nombre y apellido" name="nombreInput" value=""/>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="rutInput">R.U.T.</label>
                        <input type="text" id="rutInput" class="form-control" placeholder="Ingrese RUT" name="rutInput" maxlength="15" value=""/>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="country-floating">Isapre Actual</label>
                        <select class="form-control" id="actualInput">
                           <option value="">Selecciona</option>
                           <option value="0">Fonasa</option>
                           <option value="1">Banmedica</option>
                           <option value="2">Consalud</option>
                           <option value="3">Colmena</option>
                           <option value="4">Cruz Blanca</option>
                           <option value="5">Vidatres</option>
                           <option value="6">Mas Vida</option>
                         </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-12"> 
                      <div class="form-group">
                        <label for="mailInput">E-Mail</label>
                        <input type="text" id="mailInput" class="form-control" name="selecmailInputMail" placeholder="Correo electrónico" value=""/>
                      </div>
                    </div>
                    <div class="col-md-6 col-12"> 
                      <div class="form-group">
                        <label for="telefonoInput">Teléfono (+56)</label> 
                        <input type="number" id="telefonoInput" class="form-control" maxlength = "9" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" name="telefonoInput" placeholder="Ej: 912345678" value=""/>
                      </div>
                    </div>

                    <div class="col-12">
                     <div class="form-group">
                       <label for="comentarioInput">Comentarios</label>
                       <textarea class="form-control" id="comentarioInput" rows="3" placeholder="Ingrese comentarios"></textarea>
                     </div>
                   </div>  
                  </div>
                </form>
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-danger mail-contact" (click) = "validateSender('M')"><i data-feather='mail'></i> Solicitar ejecutivo</button>  
               <button type="button" class="btn btn-success what-contact" (click) = "validateSender('W')"><i class="fab fa-whatsapp"></i> Contacto por Whatsapp</button>
               <button type="button" class="btn btn-secondary" (click) = "selectedClose()"><i class="far fa-times-circle"></i> Cerrar</button>
            </div>
      </div>
   </div>
</div>