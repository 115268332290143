import { Component } from '@angular/core';
import { AppPlanes } from './app.planes';
import { PlanesService } from '../planes.service';
import { MailService } from '../mail.service'
import { FilterPeople } from './filter.people'
import { response } from 'express';
import swal from 'sweetalert2';  
import { AppPlanesSearch } from './app.planes.search';

const { validarRUT } = require('validar-rut');

declare var $: any;

@Component({
  selector: 'app-planes-header',
  templateUrl: './app.planes.header.html'
})

export class AppPlanesHeader {
  vtitle = 'app-planes-header';

  public phone = "+56964362062";
  public title = "Mensaje desde tu7porciento!";

  response = null;
  dataSenderPlan = [];
  dataReferido = [];

  listResult : any = [];
  listResultDataSelect : any = [];

	Cplanes = null;
	Cplan = [{ id: null, 
			  codigo: null, 
			  nombre: null, 
			  base: null, 
			  isapre: null, 
			  hospitalaria: null, 
			  ambulatoria: null, 
			  planUF: null, 
			  planPesos: null,
			  planPdf: null,
			  tipo: null,
			  zona: null,
			  logo: null,
			  compara: null
			 }]

  constructor(
    private planesApp: AppPlanes, 
    private planesService: PlanesService, 
    private mailService: MailService,
    private filterPeople: FilterPeople,
    public appPlanesSearch: AppPlanesSearch
    ) {}

    ngOnInit() {

      var rut: any;

      $('#rutInput').focusout(function(){
          var div1, div2, div3, div4;
          rut=$('#rutInput').val();
  
          if(rut.length==9){    
              div1=rut.slice(0,2); 
              div2=rut.slice(2,5);
              div3=rut.slice(5,8);
              div4=rut.slice(8,9);
  
              rut=$('#rutInput').val(div1 + "." + div2 + "." + div3 + "-" + div4);
          }
  
          if(rut.length==8){    
              div1=rut.slice(0,1);
              div2=rut.slice(1,4);
              div3=rut.slice(4,7);
              div4=rut.slice(7,8);
  
              rut=$('#rutInput').val(div1 + "." + div2 + "." + div3 + "-" + div4);
          }
      });


      $(document).on("click", function (event: any) {
        if ($(event.target).closest("#muestraLista").length === 0) {
          $('#muestraLista').hide();
        }
      });  

      
      $( "#plan-search" ).focusin(() => {
        if (this.listResultDataSelect.length > 0) {
          this.listChage();
        }
      });




    }
  

    setSearchText(dataSearch :any) {
      $('#plan-search').val(dataSearch);
      //this.planesApp.setSearchText(dataSearch);
      this.appPlanesSearch.setSearchText()
      return false;
    }    
  
    getSearchList() {
      this.listResultDataSelect = [];
      let searchParam = '';
      searchParam = $('#plan-search').val();
      this.planesService.listDataResult(searchParam,'').subscribe(res => { 
        this.listResult = res;
        this.listResultDataSelect = this.listResult;
        //console.table(res);
        this.listChage();
      });
    }
  
    listChage() {
      
      //console.table(this.listResultDataSelect.length);
      if (this.listResultDataSelect.length > 0) {
        $('#dropdownMenuButton').trigger('click');
        $('#muestraLista').show();
        //console.log('show');
      } else {
        $('#muestraLista').hide();
        //console.log('hide');
      }
    } 




  orderPlan(order) {
    //this.planesApp.order = order;
    //(<HTMLInputElement>document.getElementById('ordena')).value = order;

    //if ($('#sumFactor').val(order) != '0.00') { 
    //  if (order == '0') { order = '0' } else { order = '0' }
    //}
    $('#ordena').val(order);
    this.planesApp.SendFilterPlanes();
    return false;
	}

  clearSelected() {
		let listaPlanes = $("#ecommerce-products input:checkbox:checked");
		for (let i = 0; i < listaPlanes.length; i++) {
			 let TagCheckes = document.getElementById(listaPlanes[i].id);
       let idcheck = TagCheckes.id;
       document.getElementById(idcheck).click();
		}
    $('#compareParamsSet').val('');
	}

  CompareSelected() {

    let compareParamsSet = $('#compareParamsSet').val();
    //console.log('compareParamsSet : '+compareParamsSet);

    //let listaPlanes = $("#ecommerce-products input:checkbox:checked");
    if (compareParamsSet == '') {
      swal.fire({
        title: "tu7porciento",
        text: "Debe seleccionar planes para continuar!",
        icon: "error",
        confirmButtonText: "Aceptar",
        customClass: {
            confirmButton: "btn btn-primary"
        },
        buttonsStyling: !1
      })
      return false;
    }
   
      let url='https://apivendor.tu7.cl/';
      let listaCompare = compareParamsSet;
      let CountBeneficiarios = $('#personList tr').length - 1;
      let sumafactores = $('#sumFactor').html();
      let valorUF = $('#UFvalor').val();
      
      //for (let i = 0; i < this.planesApp.checkedPlanes.length; i++) {
      //   listaCompare += this.planesApp.checkedPlanes[i] + ',';
      //}

      //for (let i = 0; i < listaPlanes.length; i++) {
      //   let TagCheckes = document.getElementById(listaPlanes[i].id);
      //   var n = TagCheckes.id.split("-");
      //   let idcheck = n[1];
      //   listaCompare += idcheck + ',';
      //}
        listaCompare = listaCompare.slice(0, -1);
        //console.log('listaCompare : '+listaCompare);
        this.planesService.getPlanCompare(listaCompare,CountBeneficiarios,sumafactores,valorUF).subscribe(result => this.Cplanes = result);
  
      //return false;

      //$("#CompareData").empty();
      //$('#CompareData').html('comparador');
      //$('#CompareViewer').modal('show');
      return false;
  
    }  

   
    gridClick(event) {
      $( ".lomuestro" ).addClass( "d-none" )
    }

    listClick(event) {
      $( ".lomuestro" ).removeClass( "d-none" )
    }

    triggerSelected() {
      $('#CompareViewer').modal('hide');
      $('#selectedMail').modal('show');
    }    

    isValidEmail(mail) { 
      return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail); 
    }    

    validateSender(type) {   

      $('.mail-contact').prop("disabled", true);
      $('.mail-contact').attr("disabled", true);
      $('.what-contact').prop("disabled", true);
      $('.what-contact').attr("disabled", true);


      let ufValue = $('#UFvalor').val(); 
      let nombreInput = $('#nombreInput').val();
      let rutInput = $('#rutInput').val();
      let actualInput = $('#actualInput').val();
      let nactualInput = '';      
      if (actualInput == '0') { nactualInput = 'Fonasa'; }
      if (actualInput == '1') { nactualInput = 'Banmedica'; }
      if (actualInput == '2') { nactualInput = 'Consalud'; }
      if (actualInput == '3') { nactualInput = 'Colmena'; }  
      if (actualInput == '4') { nactualInput = 'Cruz Blanca'; }
      if (actualInput == '5') { nactualInput = 'Vidatres'; }
      if (actualInput == '6') { nactualInput = 'Mas Vida'; }
      let mailInput = $('#mailInput').val();
      let telefonoInput = $('#telefonoInput').val();
      let comentarioInput = $('#comentarioInput').val();

      let Passed = '0';
      let stringMessage = ''; 
  
      if (nombreInput == '') { stringMessage += '- Nombre y apellido<br>'; Passed = '0'; } else { Passed = '1'; }
      if (rutInput == '') { stringMessage += '- RUT<br>'; Passed = '0'; } else { Passed = '1'; }
      if (actualInput == '') { stringMessage += '- Seleccionar Isapre actual<br>'; Passed = '0'; } else { Passed = '1'; }
      if (mailInput == '') { stringMessage += '- Correo electrónico<br>'; Passed = '0'; } else { Passed = '1'; }
      if (telefonoInput == '') { stringMessage += '- Teléfono<br>'; Passed = '0'; } else { Passed = '1'; }
      if (comentarioInput == '') { stringMessage += '- Comentarios'; Passed = '0'; } else { Passed = '1'; }
  
      if (Passed == '0') {
        swal.fire({
          title: "tu7porciento",
          html: "Debe ingresar la siguiente información para continuar!<br><br>"+stringMessage,
          icon: "warning",
          confirmButtonText: "Aceptar",
          customClass: {
              confirmButton: "btn btn-primary"  
          },
          buttonsStyling: !1
        })
        $('.mail-contact').prop("disabled", false);
        $('.mail-contact').attr("disabled", false);
        $('.what-contact').prop("disabled", false);
        $('.what-contact').attr("disabled", false);
  
        return false;
      }

      if (!validarRUT(rutInput)) { 
        swal.fire({
          title: "tu7porciento",
          html: "El RUT ingresado no es válido!<br><br>"+stringMessage,
          icon: "warning",
          confirmButtonText: "Aceptar",
          customClass: {
              confirmButton: "btn btn-primary"
          },
          buttonsStyling: !1
        })
        $('.mail-contact').prop("disabled", false);
        $('.mail-contact').attr("disabled", false);
        $('.what-contact').prop("disabled", false);
        $('.what-contact').attr("disabled", false);
  
        return false;
      }




      if (!this.isValidEmail(mailInput)) {
        swal.fire({
          title: "tu7porciento",
          html: "El E-mail ingresado no es válido!<br><br>"+stringMessage,
          icon: "warning",
          confirmButtonText: "Aceptar",
          customClass: {
              confirmButton: "btn btn-primary"
          },
          buttonsStyling: !1
        })
        $('.mail-contact').prop("disabled", false);
        $('.mail-contact').attr("disabled", false);
        $('.what-contact').prop("disabled", false);
        $('.what-contact').attr("disabled", false);
  
        return false;
      }

      if (telefonoInput.length != 9) {
        swal.fire({
          title: "tu7porciento",
          html: "El Teléfono ingresado debe contener 9 números!<br><br>"+stringMessage,
          icon: "warning",
          confirmButtonText: "Aceptar",
          customClass: {
              confirmButton: "btn btn-primary"
          },
          buttonsStyling: !1
        })
        $('.mail-contact').prop("disabled", false);
        $('.mail-contact').attr("disabled", false);
        $('.what-contact').prop("disabled", false);
        $('.what-contact').attr("disabled", false);
  
        return false;
      }

      var beneficiarios = '';
      var table = document.querySelector("#personList tbody");
      var rows = table.children;
      for (var i = 0; i < rows.length; i++) {
          var fields = rows[i].children;
          //var rowArray = [];
          var lineVal = '';
        for (var j = 0; j < fields.length - 1; j++) {
          lineVal = fields[j].innerHTML;
          //console.log('lineVal antes : '+lineVal);

          lineVal = lineVal.replace('<span class="badge badge-light-success">','');
          lineVal = lineVal.replace('<span class="badge badge-light-danger">','');
          lineVal = lineVal.replace('</span>','');

          //console.log('lineVal después : '+lineVal);

          beneficiarios += lineVal + ',';
        }
        beneficiarios = beneficiarios.slice(0, -1);
        beneficiarios += '-';
      }
      beneficiarios = beneficiarios.slice(0, -1);

      //console.log(beneficiarios);
      //return false;

      var rowArray = [];
      //$( "mail-contact" ).addClass( "myClass" )
      //$( "what-contact" ).removeClass( "myClass" )

      let codigosPLanes = '';
      let pdfPLane = '';

      let theplans = '';
      let selectPlanes = $('#compareParamsSet').val().split(",");
      for (let i = 0; i < selectPlanes.length; i++) {
        //let TagCheckes = document.getElementById(selectPlanes[i].id);
        let TagCheckesID = selectPlanes[i];
        if (TagCheckesID != '') {
        //let pdfPLane = this.planesService.getPlan(TagCheckesID);
        //rowArray.push(pdfPLane);
        //console.log(rowArray);
        let urlPath='https://apivendor.tu7.cl/';

        $.ajax({
          url: urlPath+'getPlan.php?params=' + TagCheckesID,
          dataType: 'text',
          cache: false,
          async: false,
          type: 'get',
          success: function(response) {
              //console.log(response);
              rowArray.push(response);
          }
      });
    }
        //let pdfPLane = this.planesService.getPlan(TagCheckesID).subscribe(result => { this.dataSenderPlan.push(result); });
      }


      //console.log(rowArray);
      //console.log(rowArray.length);

      for (var x = 0; x < rowArray.length; x+=1) {
        //console.log('rowArray : '+rowArray[x]);
        var valores = rowArray[x].split(".");
        var urlPlan = 'https://www.pdfplan.cl/viewplan/'+rowArray[x];
        //var pdfFile = rowArray[x];
        //var fullUrl = urlPlan+pdfFile;
        
        codigosPLanes += urlPlan+'%0A';
        
      }      
      let vistaPLanes = $('#compareParamsSet').val();
      vistaPLanes = vistaPLanes.slice(0, -1);


      //console.log('codigosPLanes : '+codigosPLanes);
      //theplans = theplans.slice(0, -1);
      
      if (type == 'M') {
         this.selectedSendMail(nombreInput,rutInput,nactualInput,mailInput,telefonoInput,comentarioInput,beneficiarios,codigosPLanes,ufValue,vistaPLanes);
      } else {
         this.selectedSendWhatsapp(nombreInput,rutInput,nactualInput,mailInput,telefonoInput,comentarioInput,beneficiarios,codigosPLanes,ufValue);
      }


      return false; 

    }

    selectedSendMail(nombreInput,rutInput,nactualInput,mailInput,telefonoInput,comentarioInput,beneficiarios,codigosPLanes,ufValue,vistaPLanes) {
      this.mailService.sendMailEjecutivo(nombreInput,rutInput,nactualInput,mailInput,telefonoInput,comentarioInput,beneficiarios,codigosPLanes,ufValue,vistaPLanes).subscribe(result => { this.response = result; this.sendStatus(result); console.log(result); } );
      //console.log('Mail!');
      return false; 
    }    

    selectedSendWhatsapp(nombreInput,rutInput,nactualInput,mailInput,telefonoInput,comentarioInput,beneficiarios,codigosPLanes,ufValue) {
      //console.log('codigosPLanes : '+codigosPLanes);
      //return false;
      let nbeneficiarios = '';
      
      var separa = beneficiarios.split("-");

      for (var i = 0; i < separa.length; i+=1) {
        var valores = separa[i].split(",");
        nbeneficiarios += valores[0]+' '+valores[2]+', ';
      }      
      //console.log('nbeneficiarios : '+nbeneficiarios);
      nbeneficiarios = nbeneficiarios.slice(0, -2);
      //return false;

      //this.mailService.getReferidoData(nombreInput,rutInput,nactualInput,mailInput,telefonoInput,comentarioInput,codigosPLanes).subscribe(result => { 
        //this.response = result; 
        //this.sendStatus(result);  
        //console.table(result);
        //console.log( this.response.map( function(a) {return a["telefono"]} )); 
        //var phoneNumerSend = this.response.map( function(a) {return a["telefono"]} );

        //console.log('phoneNumerSend : '+phoneNumerSend);

        //return false;

        var urlLinkWhatsapp = 'https://api.whatsapp.com/send?phone=56'+'964362062'+'&text=Hola,%0AHe%20visitado%20eljovendeisapre.cl,%20favor%20necesito%20que%20me%20contacten.%0A%0ANombre:%20'+nombreInput+'%0AR.U.T.:%20'+rutInput+'%0AIsapre Actual:%20'+nactualInput+'%0AE-Mail:%20'+mailInput+'%0ATel%C3%A9fono:%20%20'+'56'+telefonoInput+'%0AComentarios:%20%20'+comentarioInput+'%0ABeneficiarios:%20%20'+nbeneficiarios+'%0APlan:%20%0A'+codigosPLanes+'%0A%0A¡Saludos!';
        window.open(urlLinkWhatsapp, '_blank');
        this.sendStatus('true');

      //});



      //$referidoPhone = str_replace('(','',$referidoPhone);
      //$referidoPhone = str_replace(')','',$referidoPhone);
      //$referidoPhone = str_replace('+56','',$referidoPhone);
      //$referidoPhone = str_replace(' ','',$referidoPhone);

      //var phoneNumerSend = separa[3];      
      
      //var urlLinkWhatsapp = 'https://api.whatsapp.com/send?phone='+phoneNumerSend+'&text=Hola,%0AHe%20visitado%20tu7porciento.cl,%20favor%20necesito%20que%20me%20contacten.%0A%0ANombre:%20'+nombreInput+'%0AR.U.T.:%20'+rutInput+'%0AIsapre Actual:%20'+nactualInput+'%0AE-Mail:%20'+mailInput+'%0ATel%C3%A9fono:%20%20'+'56'+telefonoInput+'%0AComentarios:%20%20'+comentarioInput+'%0ABeneficiarios:%20%20'+nbeneficiarios+'%0APlanes:%20%0A'+codigosPLanes+'%0A%0A¡Saludos!';
      //window.open(urlLinkWhatsapp, '_blank');
      //this.sendStatus('true');
      //console.log(urlLinkWhatsapp);
    }

    sendStatus(result) {
      //console.log('result : '+result);
       if (result === 'true') {
          //console.log('Enviado!');
          $('#selectedMail').modal('hide');

          $('.mail-contact').prop("disabled", false);
          $('.mail-contact').attr("disabled", false);
          $('.what-contact').prop("disabled", false);
          $('.what-contact').attr("disabled", false);
  


          swal.fire({
            title: "eljovendeisapre.cl",
            text: "Gracias por cotizar con nosotros, un ejecutivo te contactará a la brevedad!",
            icon: "success",
            confirmButtonText: "Aceptar",
            customClass: {
                confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
    

       } else {
          console.log('Error! : '+result);
       }
    }
    
    selectedClose() {
      //$('#CompareViewer').modal('show');
      $('#selectedMail').modal('hide');
    }    



}
