import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Plan } from './component/planes.model';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { of, pipe } from 'rxjs';
import { Subject } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})

export class PlanesService { 

  //url='http://localhost/dataMining/';
  url='https://apivendor.tu7.cl/'; 
  baseLISTA = 'getSearchResult.php';

  constructor (private http: HttpClient) {}   

  getData(params:string): Observable<any> { 
    //console.log(this.url + 'getPlanestest.php?params='+params); 
    return this.http.get<Plan[]>(this.url + 'getPlanestest.php?params='+params);
  }
  
  getPlanCompare(planID,Beneficiarios,factores,valorUF) {
     //console.log(this.url + 'getPlanCompare.php?params='+planID+'|'+Beneficiarios+'|'+factores+'|'+valorUF);
     return this.http.get<Plan[]>(this.url + 'getPlanCompare.php?params='+planID+'|'+Beneficiarios+'|'+factores+'|'+valorUF);
  }

  getPlan(params:string) {
    //console.log(this.url + 'getPlanes.php?params='+params);
    return this.http.get(this.url + 'getPlan.php?params='+params);
  }

  listDataResult(params: string, zonas: string) {
    //console.clear();
    //console.log(this.baseURL+this.baseLISTA+'?params='+params+'&zonas='+zonas);
    return this.http.get(this.url+this.baseLISTA+'?params='+params+'&zonas='+zonas);
  }


 /* 
  getData(params:string) {
    //console.log('recive : '+params);
    //return this.http.get<Plan>(this.url + 'getPlanes.php?params='+params);
    return this.http.get(this.url + 'getPlanes.php?params='+params);
    //console.log(responseBody);
  } 
 */ 


  
}
